<template>
    <div class="pages p20">
        <div class="title-btn-block">
            <div class="title-block">{{ $t("message.my_penalties") }}</div>
            <div>
              <!-- <router-link  :to="{ name: 'advances'}">
                  <div class="my-btn mr-2 el-button" style="background:#00CC00; color:#fff; margin-right: 10px;">
                      Создать
                  </div>
              </router-link> -->
                <router-link :to="{ name: 'Home' }">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
                    </div>
                </router-link>
            </div>
        </div>

        <div class="content-block">
            <div class="datetime-end bg p20 mb20">
                <el-divider content-position="left"> {{ $t("message.my_penalties") }} </el-divider>

                <div class="scrol-table crmhome__scroll my-table">
                  <table>
                        <thead>
                            <tr>
                               <th>{{ $t("message.name") }}</th>
                               <th>{{ $t("message.reason") }}</th>
                                <th>{{ $t("message.amount") }}</th>
                                <th>{{ $t("message.comment") }}</th>
                                <th>{{ $t("message.created_at") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="salary in lists" :key="salary.id">
                                <td>{{salary.name}}</td>
                                <td>{{salary.reason.name}}</td>
                                <td>{{salary.amount.toLocaleString()}} {{$t('message.summa')}}</td>
                                <td>{{salary.comment}}</td>
                                <th>{{ salary.created_at }}</th>

                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
             
        </div>
    </div>
</template>
<script>
import { getItem } from "@/utils/storage";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      penalties:[]
    };
  },
  mounted() {
    this.getTable()
  },
  computed:{
    ...mapGetters({
      lists:"penalties/list",
    })
   },
  methods: {
    ...mapActions({
      updateList: "penalties/index",
    }),
    getTable() {
      this.updateList(getItem("userId"))
        .then((res) => {
          this.penalties = res;
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
   
  },
};
</script>
